import Vue from "vue";
import Router from "vue-router";
import TopBar from "@/layouts/default/TopBar.vue";

// import FooTer from "./components/_Default/Footer.vue";
// import axios from "axios";

Vue.use(Router);

const updateActivity = (title) => {
    axios
        .put('/app/members/me/activity',
            {stay: title},)
        .then(response => {

        })
        .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);

        })
}

const requireAuthBeforeLogin = () => (from, to, next) => {
    let isAuthenticated = false;
    if (localStorage.accessToken !== undefined) {
        isAuthenticated = true;
    }

    if (isAuthenticated) return next('/');
    //메인페이지 완성전까지 사용
    // if (isAuthenticated) return next('/game/sports');
    next();
}
const requireAuth = () => async (from, to, next) => {
    let isAuthenticated = false;
    console.log('로컬스토리지', localStorage)
    console.log('로컬스토리지', localStorage.accessToken)
    if (localStorage.accessToken !== undefined) {
        isAuthenticated = true;
    }
    console.log(isAuthenticated)
    // if (isAuthenticated) return next('/');
    if (isAuthenticated) {
        updateActivity(from.matched[0].meta.title)
        // if (from.name === 'login') {
        //   return next('/game/sports');
        // } else {
        //   return next();
        // }
        return next();
    } else {
        //alert('로그인후 이용하세요')
        next('/login');
    }
}




const router = new Router({
        mode: "history",
        base: process.env.BASE_URL,
        routes: [
            {
                path: "/",
                component: () => import("@/layouts/default/Index.vue"),
                children: [
                    {
                        path: "/",
                        name: "main",
                        component: () => import("@/views/Main"),
                        meta: {
                            title: "메인페이지",
                            isBet: false,
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth()
                    },
                    {
                        path: "/deposit",
                        name: "requestDeposit",
                        component: () => import("@/views/Deposit/Request.vue"),
                        meta: {
                            title: "충전하기",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth()
                    },
                    {
                        path: "/deposit/List",
                        name: "depositList",
                        component: () => import("@/views/Deposit/List"),
                        meta: {
                            title: "충전내역",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/withdrawal",
                        name: "requestWithdrawal",
                        component: () => import("@/views/Withdrawal/Request"),
                        meta: {
                            title: "환전신청",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth()
                    },
                    {
                        path: "/withdrawal/List",
                        name: "withdrawalList",
                        component: () => import("@/views/Withdrawal/List"),
                        meta: {
                            title: "환전내역",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/customer",
                        name: "Customer",
                        component: () => import("@/views/Customer/List"),
                        meta: {
                            title: "고객센터",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/message",
                        name: "message",
                        component: () => import("@/views/Message/List"),
                        meta: {
                            title: "쪽지리스트",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/message/:id",
                        name: "messageDetail",
                        component: () => import("@/views/Message/Detail"),
                        meta: {
                            title: "쪽지보기",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/point",
                        name: "requestPoint",
                        component: () => import("@/views/Point/Request"),
                        meta: {
                            title: "포인트전환",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/point/List",
                        name: "pointList",
                        component: () => import("@/views/Point/List"),
                        meta: {
                            title: "포인트내역",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/point/Summary",
                        name: "pointSummary",
                        component: () => import("@/views/Point/Summary"),
                        meta: {
                            title: "포인트내역(일별)",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/myInfo",
                        name: "myInfo",
                        component: () => import("@/views/MyInfo/List"),
                        meta: {
                            title: "포인트내역",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/partner/dashboard",
                        name: "dashboard",
                        component: () => import("@/views/Agency/dashboard"),
                        meta: {
                            title: "대시보드",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },

                    {
                        path: "/partner/betHistory",
                        name: "betHistory",
                        component: () => import("@/views/Agency/BetHistory"),
                        meta: {
                            title: "베팅내역",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/partner/exchange",
                        name: "AgencyExchange",
                        component: () => import("@/views/Agency/Exchange"),
                        meta: {
                            title: "충환전내역",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/myAgency",
                        name: "MyAgency",
                        component: () => import("@/views/Agency/MyAgency"),
                        meta: {
                            title: "대리점리스트",
                            appBarFullWidth: false
                        },
                        beforeEnter: requireAuth(),
                    },
                    {
                        path: "/partner/member",
                        name: "member",
                        components: {

                            default: () => import("@/views/Member/List.vue")
                        },
                        beforeEnter: requireAuth(),
                        meta: {
                            title: "회원 리스트"
                        }
                    },
                    {
                        path: "/partner/logOn",
                        name: "logOnList",
                        components: {
                            default: () => import("@/views/Member/LogOn.vue")
                        },
                        beforeEnter: requireAuth(),
                        meta: {
                            title: "현재접속자"
                        }
                    },
                    {
                        path: "/partner/gameSummary",
                        name: "gameSummary",
                        components: {
                            default: () => import("@/views/Agency/GameSummary.vue")
                        },
                        beforeEnter: requireAuth(),
                        meta: {
                            title: "게임통계"
                        }
                    },

                    {
                        path: "/notice/:type",
                        name: "notice",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Notice/List")
                        },
                        beforeEnter: requireAuth(),
                        props: {
                            title: "공지사항",
                            appBarFullWidth: false
                        }
                    },

                    {
                        path: "/notices/:id",
                        name: "noticeDetail",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Notice/Detail"),
                        },
                        beforeEnter: requireAuth(),
                        props: {
                            title: "공지사항"
                        }
                    },

                    {
                        path: "/Customer/:id",
                        name: "customerDetail",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Customer/Detail"),
                        },
                        beforeEnter: requireAuth(),
                        props: {
                            title: "고객센터 상세",
                            appBarFullWidth: false
                        }
                    },

                    {
                        path: "/mybet",
                        name: "mybetList",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Mybet/List"),
                        },
                        beforeEnter: requireAuth()
                        , props: {
                            title: "베팅내역",
                            appBarFullWidth: false
                        }
                    },

                    {
                        path: "/liveCasino",
                        name: "liveCasino",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Game/Casino/List.vue"),
                        },
                        beforeEnter: requireAuth()
                        , props: {
                            title: "라이브카지노",
                            appBarFullWidth: false
                        }
                    },

                    {
                        path: "/myCalculation",
                        name: "myCalculation",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Agency/Calculation/Me.vue"),
                        },
                        beforeEnter: requireAuth()
                        , props: {
                            title: "내정산",
                            appBarFullWidth: false
                        }
                    },

                    {
                        path: "/otherCalculation",
                        name: "otherCalculation",
                        components: {
                            header: TopBar,
                            default: () => import("@/views/Agency/Calculation/Other.vue"),
                        },
                        beforeEnter: requireAuth()
                        , props: {
                            title: "하위정산",
                            appBarFullWidth: false
                        }
                    },

                ],
            },
            {
                path: "/",
                component: () => import("@/layouts/auth/Index.vue"),
                children: [
                    {
                        path: "/slots/:prdId/:type",
                        name: "slot",
                        components: {
                            default: () => import("@/views/Game/Casino/Slot.vue"),
                        },
                        beforeEnter: requireAuth()
                        , props: {
                            title: "슬롯",
                            appBarFullWidth: false
                        }
                    },
                    {

                        path: "/member/detail/:id",
                        name: "memberDetail",
                        components: {

                            default: () => import("@/views/Member/Detail.vue")
                        },
                        beforeEnter: requireAuth(),
                        meta: {
                            title: "회원 상세"
                        }

                    },

                    {
                        path: "/moneyLog/:id",
                        name: "moneyLog",
                        components: {

                            default: () => import("@/views/Agency/MoneyLog.vue")
                        },
                        beforeEnter: requireAuth(),
                        meta: {
                            title: "머니지급/회수"
                        }
                    },
                    {
                        path: "/partner/createMember",
                        name: "memberCreate",
                        components: {

                            default: () => import("@/views/Agency/CreateMember.vue")
                        },
                        beforeEnter: requireAuth(),
                        meta: {
                            title: "회원 생성"
                        }
                    },
                    {
                        path: "/login",
                        name: "login",
                        components: {
                            default: () => import("@/views/Member/Login.vue"),
                        },
                        beforeEnter: requireAuthBeforeLogin(),
                        meta: {
                            title: "로그인"
                        }

                    }
                ]
            }


        ],
        scrollBehavior(to, from, savedPosition) {
            // always scroll to top
            return {top: 0}
        }
        ,
    })
;
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0)
// })

export default router;
