<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        app
        v-if="$vuetify.breakpoint.mdAndDown"
        class="overflow-y-auto"
        style="background-color:#141517"
    >
      <div class="text-right pa-2" @click="drawer=!drawer">
        <v-icon>mdi-close</v-icon>
      </div>
      <!--모바일 왼쪽-->
      <div class="myinfo MuiBox-root css-0">
        <div class="MuiGrid-root MuiGrid-container css-19rder0">
          <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto css-3uq6qv">
            <img :src="require(`@/assets/img/icon_level0${gradeValue}.gif`)" alt="">
          </div>
          <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true css-kxu0dz">
            <div class="MuiList-root MuiList-padding css-1ontqvh">
              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root nickname css-1rvc1r0"
                   tabindex="0" role="button">{{ nickname }}<span class="MuiTouchRipple-root css-w0pj6f"></span></div>
              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-1487rk3" tabindex="0"
                   role="button">
                <div class="MuiListItemText-root money css-13en0ss"><span
                    class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6"><span>보유머니</span><span>{{
                    balance.money | comma
                  }}</span></span>
                </div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-zep669" focusable="false" aria-hidden="true"
                     viewBox="0 0 24 24" data-testid="ArrowForwardIosIcon">
                  <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                </svg>
                <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-1487rk3" tabindex="0"
                   role="button"
                   @click="$move('/point')"
              >
                <div class="MuiListItemText-root css-1tt25oe"><span
                    class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6"><span>포인트</span>{{
                    balance.mileage | comma
                  }}</span>
                </div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-zep669" focusable="false" aria-hidden="true"
                     viewBox="0 0 24 24" data-testid="ArrowForwardIosIcon">
                  <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                </svg>
                <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
              <!--              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-1487rk3" tabindex="0"
                                 role="button">
                              <div class="MuiListItemText-root css-1tt25oe"><span
                                  class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6"><span>콤프</span>2,320</span>
                              </div>
                              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-zep669" focusable="false" aria-hidden="true"
                                   viewBox="0 0 24 24" data-testid="ArrowForwardIosIcon">
                                <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                              </svg>
                              <span class="MuiTouchRipple-root css-w0pj6f"></span></div>-->
              <!--              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-1487rk3" tabindex="0"
                                 role="button">
                              <div class="MuiListItemText-root css-1tt25oe"><span
                                  class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6"><span>게임중머니</span>??</span>
                              </div>
                              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-ylccsr" focusable="false" aria-hidden="true"
                                   viewBox="0 0 24 24" data-testid="RefreshIcon">
                                <path
                                    d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path>
                              </svg>
                              <span class="MuiTouchRipple-root css-w0pj6f"></span></div>-->
              <!--              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-e0yuwi" tabindex="0"
                                 role="button">
                              <button
                                  class="MuiButton-root MuiButton-contained MuiButton-containedError MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-j4py5u"
                                  tabindex="0" type="button">보유머니로 이동<span class="MuiTouchRipple-root css-w0pj6f"></span></button>
                              <span class="MuiTouchRipple-root css-w0pj6f"></span></div>-->
            </div>
            <hr class="MuiDivider-root MuiDivider-fullWidth css-pfn413">
            <div class="MuiList-root MuiList-padding css-1bxpwt9">
              <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root css-1487rk3" tabindex="0"
                   role="button">
                <div class="MuiListItemText-root logout css-1r43z0p" @click="logout"><span
                    class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">로그아웃</span></div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true"
                     viewBox="0 0 24 24" data-testid="LogoutIcon">
                  <path
                      d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path>
                </svg>
                <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
            </div>
          </div>
        </div>
      </div>

      <div class="MuiList-root MuiList-padding MuiList-subheader category css-19bcukb">
        <!--        <div class="MuiListSubheader-root MuiListSubheader-gutters MuiListSubheader-sticky css-1peyvuj"
                     id="nested-list-subheader">카테고리
                </div>-->
        <div class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer  css-e0yuwi" tabindex="0"
             role="button">
          <div
              @click="$move('/liveCasino')"
              class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">라이브 카지노</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>

        <div
            @click="launchGame({prdId:0})"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer  css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">슬롯</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>

        <div
            @click="$move('/customer')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer  css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">고객센터</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/notice/EVENT')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer  css-e0yuwi" tabindex="0"
            role="button">
          <div
              class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">이벤트</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/notice/NOTICE')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer  css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">이용규정</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/notice/FAQ')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer  css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">FAQ</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/deposit')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">충전</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/withdrawal')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">환전</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/point')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">포인트</span></div>
          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
        <div
            @click="$move('/myInfo')"
            class="MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root outer css-e0yuwi" tabindex="0"
            role="button">
          <div class="MuiListItemText-root css-1tsvksn"><span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-dojtk6">마이페이지</span></div>

          <span class="MuiTouchRipple-root css-w0pj6f"></span></div>
      </div>


    </v-navigation-drawer>

    <v-app-bar
        absolute
        app
        dark
        id="appBar"
        elevation="0"
        class="justify-center"
        :height="$vuetify.breakpoint.lgAndUp ? '100' : '60'"
    >
      <div
          class="pa-0 px-lg"
      >
        <div class="align-center position-absolute right-0 left-0"
             style="min-width:200px !important;" v-if="$vuetify.breakpoint.mdAndDown">
          <v-img
              class="shrink hidden-lg-and-up mx-auto"
              contain
              src="@/assets/img/logo.png"
              transition="scale-transition"
              :width="$vuetify.breakpoint.lgAndUp ? 150: 150"
              :height="$vuetify.breakpoint.lgAndUp ? 160: 48"
              @click="goLink('main')"
              style=""
          />
        </div>
        <div class="d-flex "

             :class="$vuetify.breakpoint.lgAndUp? 'align-center justify-center':'justify-space-between'"
        >
          <div>
            <span class="hidden-md-and-up" style="z-index:999">
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            </span>
          </div>
          <div>
            <user-pc
                v-if="$vuetify.breakpoint.lgAndUp"
                :nowTime="nowTime"
                :balance="balance"
                :unreadMessage="unreadMessage"
                :nickname="nickname"
                :gradeValue="gradeValue"
                :menuItems="menuItems"
                :isLogin="isLogin"
                :role="role"
                :hasChildren="hasChildren"
                v-on:openLoginDialog="openLoginDialog"
                v-on:openRecommenderDialog="openRecommenderDialog"
                v-on:logout="logout"
                v-on:goLink="goLink"
                v-on:checkMenuTab="checkMenuTab"
                v-on:autoAsk="autoAsk"
                v-on:launchGame="launchGame"
            ></user-pc>
            <user-mobile
                v-else
                :nowTime="nowTime"
                :balance="balance"
                :unreadMessage="unreadMessage"
                :nickname="nickname"
                :gradeValue="gradeValue"
                :drawerRight.sync="drawerRight"
                :isLogin="isLogin"
                :role="role"
                :hasChildren="hasChildren"
                v-on:openLoginDialog="openLoginDialog"
                v-on:openRecommenderDialog="openRecommenderDialog"
                v-on:toggleDrawerRight="toggleDrawerRight"
                v-on:logout="logout"
                v-on:goLink="goLink"
                v-on:checkMenuTab="checkMenuTab"
                v-on:autoAsk="autoAsk"
                v-on:launchGame="launchGame"
            >
            </user-mobile>
          </div>

          <!--          <div class="align-center" style="min-width:200px !important;" v-if="$vuetify.breakpoint.mdAndDown">-->
          <!--            <v-img-->
          <!--                class="shrink hidden-lg-and-up mx-auto"-->
          <!--                contain-->
          <!--                src="@/assets/img/logo.png"-->
          <!--                transition="scale-transition"-->
          <!--                :width="$vuetify.breakpoint.lgAndUp ? 150: 150"-->
          <!--                :height="$vuetify.breakpoint.lgAndUp ? 160: 48"-->
          <!--                @click="goLink('main')"-->
          <!--                style=""-->
          <!--            />-->
          <!--          </div>-->


        </div>

      </div>

    </v-app-bar>

    <confirm-dialog
        :dialog.sync="dialog.confirm"
        :confirmData="dialog.confirmData"
        v-on:confirmLeftBtnAction="confirmLeftBtnAction"
        v-on:confirmRightBtnAction="confirmRightBtnAction">
    </confirm-dialog>
    <recommender-dialog
        v-if="dialog.recommender"
        :dialog.sync="dialog.recommender"
        v-on:recommenderAlertAction="recommenderAlertAction"
        v-on:checkRecommender="checkRecommender"
    ></recommender-dialog>
    <login-dialog
        :dialog.sync="dialog.login"
        v-on:login="login"
    />
    <join-dialog
        v-if="dialog.join"
        :joinDialog.sync="dialog.join"
        :code="dialog.joinData.recommender"
    />
  </div>
</template>

<script>
// import axios from "axios";
import userPc from "@/components/_Default/User/Pc.vue"
import userMobile from "@/components/_Default/User/Mobile.vue"
import sideLeague from "@/components/_Default/sideLeagues.vue"
import RecommenderDialog from '@/components/Member/RecommenderDialog.vue'
import LoginDialog from '@/components/Member/LoginDialog.vue'
import JoinDialog from '@/components/Member/JoinDialog.vue'
import {eventBus} from '@/plugins/eventBus';
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";

export default {
  name: "DefaultTopBar",
  data: () => ({
    socketInterval:null,
    role: localStorage.role,
    interval: null,
    balanceInterval: null,
    drawer: false,
    drawerRight: false,
    nowTime: 0,
    balance: {},
    unreadMessage: 0,
    nickname: '',
    gradeValue: '',
    hasChildren: 'true',
    menuItems: [
      {title: '입금신청', path: '/deposit', icon: 'mdi-bank-transfer-in'},
      {title: '출금신청', path: '/withdrawal', icon: 'mdi-bank-transfer-in'},
      {title: '고객센터', path: '/customer', icon: 'mdi-face-agent'},
      {title: '베팅내역', path: '/mybet', icon: 'mdi-cart-outline'},
      {title: '공지사항', path: '/notice/NOTICE', icon: 'mdi-clipboard-play-outline'},

    ],

    AgencyMenuItems: [

      {
        icon: 'mdi-face-agent',
        title: '회원관리',
        path: '/member',
        items: [
          {title: '회원리스트', path: '/member'},
          {title: '대리점리스트', path: '/myAgency'},
          /*{title: '현재접속자', path: '/autoAnswer'}*/
        ]
      },
      {
        icon: 'mdi-currency-krw',
        title: '충환전관리',
        path: '/Customer',
        items: [
          {title: '충환전내역', path: '/Agency/Exchange'}
        ]
      },

      {
        icon: 'mdi-trophy',
        title: '베팅',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory'}
        ]
      },

      {
        icon: 'mdi-calculator',
        title: '정산',
        path: '/',
        items: [
          /*{title: '대시보드', path: '/dashboard'},*/
          {title: '내정산', path: '/myCalculation'},
          {title: '하위정산', path: '/otherCalculation'},
          /*{title: '정산내역', path: '/betHistory/MINI_GAME'},
          {title: '정산요청', path: '/betHistory/MINI_GAME'}*/
        ]
      },
    ],
    dialog: {
      login: false,
      recommender: false,
      alert: false,
      alertOption: {
        persistent: true
      },
      alertText: '',
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
      join: false,
      joinData: {
        recommender: ''
      }
    },
    menuTab: null,
    currPath: '',
    connected: false,
    isLogin: false,
    subscriptionId:'',
    stompClient: null
  }),
  components: {
    userPc,
    userMobile,
    sideLeague,
    RecommenderDialog,
    LoginDialog,
    JoinDialog
  },
  methods: {
    openLoginDialog: function () {
      this.dialog.login = true;
    },

    launchGame: function (item) {
      this.$launchGame(item)
    },

    login: function (id, pw) {
      const username = id;
      const password = pw;

      if (!username || !password) {
        return false;
      }
      this.$store
          .dispatch("LOGIN", {username, password})
          .then(() => {
            this.$move("/")
            //this.$router.push({name: "main"});
          })
          .catch(ex => {
            console.log(ex)
            // console.log(ex.response.status)
            this.dialog.alertText = ex.response.data.message;
            this.dialog.alert = true;
            console.log(this.dialog)
          });

    },
    recommenderAlertAction: function () {
      this.dialog.recommender = !this.dialog.recommender
    },
    openRecommenderDialog: function () {
      this.dialog.recommender = true
    },
    goRegist: function (recommender) {
      this.dialog.recommender = false
      this.dialog.join = true
      this.dialog.joinData.recommender = recommender
    },
    checkRecommender: async function (recommender) {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};

      await axios
          .post('/app/member/check/recommender',
              {code: recommender},)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "잘못된 추천인 코드입니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })


      if (isSuccess.flag) {
        this.goRegist(recommender)
      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.checkRecommender(recommender)
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
              this.dialog.alertText = this.$error403msg
            } else {
              this.dialog.alertText = refreshTokenResult.msg
            }
            this.dialog.alert = true;

          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = this.$error403msg
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '잘못된 추천인 코드입니다.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },
    toggleDrawerRight: function () {
      this.drawerRight = !this.drawerRight
    },
    setMenuTab: function () {
      this.currPath = this.$route.path
      const tabIndex = this.menuItems.findIndex(tab => tab.path === this.$route.path)
      this.menuTab = tabIndex

    },
    checkMenuTab: async function (targetPath) {
      console.log(targetPath, this.currPath)
      if (targetPath === this.currPath) {
        await eventBus.getSiteInfo()
        eventBus.getData()
      } else {
        this.$router.push({path: targetPath})
      }
    },
    goLink: function (target) {
      this.$goLink(target)
    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    logout: function () {
      this.dialog.confirmData.leftBtn = '확인'
      this.dialog.confirmData.rightBtn = '취소'
      this.dialog.confirmData.title = '로그아웃'
      this.dialog.confirmData.content = '로그아웃 하시겠습니까?'
      this.dialog.confirm = true
    },
    confirmRightBtnAction: function () {
      this.dialog.confirmData.leftBtn = ''
      this.dialog.confirmData.rightBtn = ''
      this.dialog.confirmData.title = ''
      this.dialog.confirmData.content = ''
      this.dialog.confirm = false
    },
    confirmLeftBtnAction: async function () {
      //로그아웃 api 추가
      // axios
      //     .post("/app/auth/signOut", {})
      //     .then(response => {
      //       console.log('refresh !!! STORE', response)
      //
      //       // 성공적으로 로그인이 되었을 경우
      //       if (response.status === 200) {
      //         console.log('signout success')
      //       } else {
      //         console.log('signout fails')
      //       }
      //
      //     }).catch(async ex => {
      //   console.log("signout fail", ex)
      // });

      if (this.$logout().flag) {
        this.confirmRightBtnAction()
        this.$move("/")
      } else {
        this.confirmRightBtnAction()
        this.dialog.alert = true;
        this.dialog.alertText = '로그아웃을 실패하였습니다. 관리자에게 문의해주세요.'
      }
    },

    setDate() {
      let weekday = ['일', '월', '화', '수', '목', '금', '토']
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
      let date = new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
      let week = weekday[new Date().getDay()];
      let hh = new Date().getHours() < 10 ? "0" + new Date().getHours() : new Date().getHours();
      let mm = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return {
        'year': year
        , 'month': month
        , 'date': date
        , 'week': week
        , 'hh': hh
        , 'mm': mm
        , 'ss': ss
      }
    },
    nowTimes() {
      this.nowTime =
          // this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + "-" +
          this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + " (" + this.setDate().week + ") " + "<br />" + this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      // this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      eventBus.getNowTime(this.nowTime.replace('<br />', ''))
    },

    getMember: async function () {

      if (this.$route.path !== '/login' && this.$route.path !== '/join') {
        await this.$updateMyInfo()
      }

      this.nickname = localStorage.nickname
      this.gradeValue = localStorage.gradeValue
      this.hasChildren = localStorage.hasChildren

      console.log('getMember', this.hasChildren)

    },
    getBalance: function () {
      console.log('getBalance')
      axios
          .get('/app/member/balance',
              {
                params: {},
              })
          .then(response => {
            // console.log(response)
            this.balance = response.data
          })
          .catch(async ex => {
            console.log(ex)
            if (ex.response.status === 401) {
              console.log('getBalance end')
              clearInterval(this.balanceInterval)
              this.balanceInterval = null
              //
              // let refreshTokenResult = await this.$refreshToken()
              // console.log(refreshTokenResult)
              // if (refreshTokenResult.flag) {
              //   await this.getBalance()
              // } else {
              //   if (refreshTokenResult.status === 403) {
              //     this.dialog.alertActionType = 'logout'
              //     this.dialog.alertOption.persistent = true
              //     this.dialog.alertText = this.$error403msg
              //   } else {
              //     this.dialog.alertText = refreshTokenResult.msg
              //   }
              //   this.dialog.alert = true;
              // }
            } else if (ex.response.status === 403) {
              this.clearInterval()
              this.dialog.alert = true;
              this.dialog.alertText = this.$error403msg
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })

    },
    getUnreadMessage: async function () {
      await axios
          .get('/app/messages/unread',
              {
                params: {},
              })
          .then(response => {
            // console.log(response.data)
            this.unreadMessage = response.data
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getUnreadMessage()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                  this.dialog.alertText = this.$error403msg
                } else {
                  this.dialog.alertText = refreshTokenResult.msg
                }
                this.dialog.alert = true;


              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = this.$error403msg
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }
          })
      if (this.unreadMessage > 0 && !this.$route.path.includes('/message')) {
        this.checkUnreadMessage();
      }
    },
    checkUnreadMessage: function () {
      this.dialog.alertText = '읽지 않은 매세지가 있습니다. 확인하시겠습니까?';
      this.dialog.alert = true;
      this.dialog.alertActionType = 'goMessage'
    },


    getData: function () {
      this.getMember()
      this.getBalance()
      this.getUnreadMessage()
      this.setMenuTab()
    },
    memberStatusCheck: function () {
      // console.log(localStorage.memberId)
      // console.log(localStorage.username)
      // console.log(localStorage.nickname)
      // console.log(localStorage.grade)
      // console.log(localStorage.gradeValue)
      // console.log(localStorage.status)
      // console.log(localStorage.statusValue)
      console.log(localStorage)
      const status = localStorage.status;
      if (status === 'AUTHORIZED') {

      } else {
        this.dialog.alertText = '접속 불가한 계정입니다. 관리자에게 문의하시길 바랍니다.';
        this.dialog.alert = true;
        this.dialog.alertActionType = 'doNotAccess'
        // if (localStorage.status === 'UNAUTHORIZED') {
        //
        // } else if (localStorage.status === 'PAUSE') {
        //
        // } else if (localStorage.status === 'WITHDRAW') {
        //
        // }
      }
    },

    showAlarm: function (item) {
      this.dialog.alert = true;
      this.dialog.alertText = item.message;
      this.dialog.alertActionType = '';
    },

    autoAsk: async function () {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .post('/app/store/charge/autoAsk',
              {
                headers: {"Content-Type": `application/json`}
              })
          .then(response => {
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
              isSuccess.msg = response.data.message;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "1:1 문의를 실패하였습니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })

      if (isSuccess.flag) {
        this.dialog.alertText = isSuccess.msg;
        this.dialog.alert = true;
        this.dialog.alertActionType = 'goCustomer';
        // this.dialog.write = false;
        // this.dialog.snackBar = true;
        // this.dialog.snackBarText = isSuccess.msg;
      } else {

        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.autoAsk()
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
              this.dialog.alertText = this.$error403msg
            } else {
              this.dialog.alertText = refreshTokenResult.msg
            }
            this.dialog.alert = true;

          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = this.$error403msg
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '1:1 문의를 실패하였습니다.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },
    subScribeInplay: function () {
      this.stompClient.subscribe(`/queue/${localStorage.memberId}/alert`, async tick => {
        this.subscriptionId = tick.headers.subscription
        console.log('topbar!')
        this.showAlarm(JSON.parse(tick.body))
      });
    },
    async connect() {
      this.stompClient = this.$initSocket()
      this.stompClient.connect(
          {},
          frame => {
            this.connected = true;
            this.subScribeInplay()

          },
          error => {
            console.log(error);
            this.connected = false;
          }
      );
    },
    disconnect() {
      this.stompClient.unsubscribe(this.subscriptionId)
      this.stompClient.disconnect()
      this.$socketDisconnect()
      this.connected = false;
      clearInterval(this.socketInterval)
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
    socketCheck: function() {
      if(!this.stompClient.connected) {
        this.connect();
      }
    },
    checkIsLogin: function () {
      if (localStorage.accessToken !== undefined) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    clearInterval: function () {
      clearInterval(this.interval)
      this.interval = null
      clearInterval(this.balanceInterval)
      this.balanceInterval = null
    },
    startBalanceInterval: async function () {
      console.log('getBalance restart')
      for (let i = 0; i < 3; i++) {
        if (this.balanceInterval === null) {
          console.log('getBalance restart success')
          this.balanceInterval = setInterval(this.getBalance.bind(this), 5000);
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    },
  },
  mounted() {

    if (localStorage.accessToken !== undefined) {
      this.getData();
    }
    this.checkIsLogin()
    eventBus.getSiteInfo()
  },
  created() {
    this.$eventBusInitAlert()
    eventBus.$off('updateTopBarData')
    eventBus.$on('updateTopBarData', () => {
      if (localStorage.accessToken !== undefined) {
        this.getData()
      }
    })
    eventBus.$off('clearInterval')
    eventBus.$on('clearInterval', () => {
      this.clearInterval()
    })
    eventBus.$off('startBalanceInterval')
    eventBus.$on('startBalanceInterval', () => {
      this.startBalanceInterval()
    })
    console.log(this.balanceInterval)
    this.nowTimes();
    this.setDate()
    if(this.connected !== true){
      this.connect();
    }
    setInterval(this.nowTimes.bind(this), 1000);
    //회원 상태 체크
    if (localStorage.accessToken !== undefined) {
      this.interval = setInterval(this.memberStatusCheck.bind(this), 3000);
      this.balanceInterval = setInterval(this.getBalance.bind(this), 5000);
    }
    this.socketInterval = setInterval(this.socketCheck.bind(this), 3000);
    //회원정보 불러오기
    //setInterval(this.getMember.bind(this), 5000);
  },
  beforeDestroy() {
    this.disconnect()
    clearInterval(this.socketInterval)
  },

  destroyed() {
  },

  watch: {
    $route(to, from) {
      // console.log(to,from)
      //this.getData()
      this.checkIsLogin()
      if(this.connected !== true){
        this.connect();
      }
      this.currPath = to.path
      // console.log('currpath!!@!',this.currPath)
    },
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {

          this.showAlert()
        }
      },
      deep: true

    }
  }
}
</script>

<style scoped>
/*ul {*/
/*  list-style: none;*/
/*}*/
/*ul li {*/
/*  float:left;*/
/*  font-size: 24px;*/
/*}*/
/*.menuTag {*/
/*  text-decoration: none;*/
/*  color: white;*/
/*}*/
/deep/ #appBar .v-toolbar__extension {
  justify-content: center;
  background-color: #1e1e1e;
  border-bottom: 1px solid #FFAE00 !important;
}

#appBar {
  border-bottom: 1px solid rgb(31, 121, 254);
}

::v-deep #appBar .v-toolbar__content {
  justify-content: center;
  display: block;
}

.v-tab {

  /*font-size: 0.905 rem;*/
  /*font-family: NanumGothic !important;*/
  font-weight: 600;
  /*color:#c3c3c3 !important;*/
}

.menuTab {
  border-bottom: 1px solid #FFAE00 !important;
}

.selectedMenu, .v-tab--active {
  /*background-color: #2196f3*/
  background-color: #FFAE00 !important;
  color: #000 !important;
  /*background: radial-gradient(#762006bf, transparent);*/
  font-weight: 0 !important;
}

p {
  margin-right: 5px
}

nav {
  height: calc(var(--vh, 1vh) * 100) !important;
}


.left-partner-menu .v-list-item__title {
  color: #989292;
}

.left-partner-menu .v-list-item {
  padding-left: 0px !important;
}

.left-partner-menu .v-list-item--active {
  padding-left: 0px !important;
}

.left-partner-menu .v-list-item__content {
  padding-left: 10px !important;
}

.v-application .left-partner-menu .primary--text {
  color: #636363 !important;
  caret-color: #636363 !important;
}

</style>

