<template>
  <div>

    <div class="top_header css-ulmaqy text-center">
      <div class="MuiContainer-root MuiContainer-maxWidthLg css-10p9vcd">
        <div class="MuiBox-root css-gg4vpm">
          <div class="MuiBox-root css-0">
            <button
                class="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root btn_myinfo css-1am5865"
                tabindex="0" type="button">
              <img :src="require(`@/assets/img/icon_level0${gradeValue}.gif`)" alt="">
              <span
                  class="nickname">{{ nickname }}</span><span class="MuiTouchRipple-root css-w0pj6f"></span></button>
            <button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1eb7a56" tabindex="0"
                    type="button" @click="$move('/message')">
              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true"
                   viewBox="0 0 24 24" data-testid="MailIcon">
                <path
                    d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"></path>
              </svg>
              <span class="MuiTouchRipple-root css-w0pj6f"></span><span
                v-if="unreadMessage > 0">{{ unreadMessage }}</span></button>
            <!--            <button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1eb7a56" tabindex="0"-->
            <!--                    type="button">-->
            <!--              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">-->
            <!--                <rect width="24" height="24" fill="none"></rect>-->
            <!--                <path-->
            <!--                    d="M969,490v-4a1,1,0,0,0-1-1H954v1a1,1,0,0,1-2,0v-1h-4a1,1,0,0,0-1,1v4a2,2,0,0,1,0,4v4a1,1,0,0,0,1,1h4v-1a1,1,0,0,1,2,0v1h14a1,1,0,0,0,1-1v-4a2,2,0,0,1,0-4Zm-15,4a1,1,0,0,1-2,0v-4a1,1,0,0,1,2,0Z"-->
            <!--                    transform="translate(-946 -480)"></path>-->
            <!--              </svg>-->
            <!--              <span class="MuiTouchRipple-root css-w0pj6f"></span></button>-->
            <div role="group" class="MuiButtonGroup-root MuiButtonGroup-contained group_money css-12aqfu3"
                 aria-label="small inherit button group">
              <button
                  class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root deposit MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContainedPrimary css-15pdifl "
                  tabindex="0" type="button" @click="$move('/deposit')">충전<span
                  class="MuiTouchRipple-root css-w0pj6f "></span></button>
              <button
                  class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root withdraw MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContainedPrimary css-15pdifl mx-1"
                  tabindex="0" type="button" @click="$move('/withdrawal')">환전<span
                  class="MuiTouchRipple-root css-w0pj6f"></span></button>
              <button
                  class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root history MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContainedPrimary css-15pdifl "
                  tabindex="0" type="button" @click="$move('/deposit/List')">내역<span
                  class="MuiTouchRipple-root css-w0pj6f"></span></button>
            </div>
            <button
                class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-em93ji"
                tabindex="0" type="button" @click="$move('/partner/dashboard')" >
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 124 130" xml:space="preserve"><g><g><path fill="#FFFFFF" d="M57.23,67.2l-2.54,14.43c-0.24,1.36-0.23,2.41,0.04,3.15c0.27,0.74,0.72,1.26,1.34,1.57 c0.63,0.31,1.55,0.51,2.79,0.6c1.23,0.09,2.38,0.16,3.44,0.2l-0.33,1.87H37.22l0.33-1.87c1.85-0.05,3.24-0.15,4.19-0.31 c0.94-0.16,1.73-0.47,2.34-0.92c0.61-0.46,1.08-1.04,1.4-1.76c0.32-0.71,0.59-1.7,0.81-2.94l5.29-29.98 c0.44-2.5,0.65-4.27,0.61-5.31c-0.03-1.05-0.54-1.82-1.53-2.31c-0.99-0.5-2.76-0.75-5.3-0.75l0.33-1.87H69.1 c1.05,0,3.51,0.18,7.39,0.54c1.25,0.11,2.54,0.49,3.87,1.12c1.33,0.63,2.53,1.5,3.59,2.6c1.07,1.1,1.85,2.41,2.36,3.91 c0.51,1.51,0.6,3.15,0.29,4.92c-0.35,1.97-1.06,3.75-2.11,5.34c-1.07,1.58-2.51,2.96-4.33,4.12c-1.83,1.16-4.03,2.05-6.6,2.69 c-2.58,0.63-5.5,0.95-8.76,0.95H57.23z M57.56,65.32h4.76c2.88,0,5.34-0.35,7.36-1.04c2.03-0.69,3.7-1.86,5.01-3.51 c1.31-1.64,2.21-3.85,2.7-6.62c0.73-4.15-0.11-7.26-2.54-9.33c-0.97-0.82-2-1.35-3.09-1.6c-1.09-0.25-2.48-0.37-4.15-0.37 c-1.93,0-3.37,0.27-4.31,0.82c-0.95,0.54-1.63,1.36-2.04,2.45c-0.41,1.09-0.8,2.7-1.17,4.83L57.56,65.32z"></path></g></g></svg>
              <span class="MuiTouchRipple-root css-w0pj6f"></span></button>
          </div>

          <div class="MuiBox-root css-1djk842">
            <!--            <button
                            class="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root account css-k00atc"
                            tabindex="0" type="button" data-bs-toggle="modal" data-bs-target="#modal_my_account"
                            data-id="my_wallet">
                          게임중머니<span style="color: rgb(67, 200, 222);">??</span>원<span
                            class="MuiTouchRipple-root css-w0pj6f"></span>
                        </button>-->
            <!--            <button
                            class="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-jg1iak"
                            tabindex="0" type="button">
                          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true"
                               viewBox="0 0 24 24" data-testid="RefreshIcon">
                            <path
                                d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path>
                          </svg>
                          <span class="MuiTouchRipple-root css-w0pj6f"></span></button>
                        <button
                            class="MuiButton-root MuiButton-contained MuiButton-containedError MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-h7bck3"
                            tabindex="0" type="button">이동<span class="MuiTouchRipple-root css-w0pj6f"></span></button>-->
            <button
                class="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root account css-654dsj"
                tabindex="0" type="button" data-bs-toggle="modal" data-bs-target="#modal_my_account"
                data-id="my_wallet">
              보유머니 : <span style="color: rgb(67, 200, 222);">{{ balance.money | comma }}</span>원<span
                class="MuiTouchRipple-root css-w0pj6f"></span></button>
            <button
                class="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root account css-1a4kdcd"
                tabindex="0" type="button" data-bs-toggle="modal" data-bs-target="#modal_my_account"
                data-id="my_wallet">
              포인트 : <span style="color: rgb(67, 200, 222);">{{ balance.mileage | comma }}</span>P<span
                class="MuiTouchRipple-root css-w0pj6f"></span>
            </button>
            <button
                class="MuiButton-root MuiButton-contained MuiButton-containedError MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-1g5wha5"
                tabindex="0" type="button" @click="$move('/point')">전환<span
                class="MuiTouchRipple-root css-w0pj6f"></span></button>
            <!--            <button
                            class="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root account css-k00atc"
                            tabindex="0" type="button" data-bs-toggle="modal" data-bs-target="#modal_my_account"
                            data-id="my_wallet">
                          콤프<span style="color: rgb(67, 200, 222);">2,320</span>P<span
                            class="MuiTouchRipple-root css-w0pj6f"></span>
                        </button>
                        <button
                            class="MuiButton-root MuiButton-contained MuiButton-containedError MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButtonBase-root  css-1g5wha5"
                            tabindex="0" type="button">전환<span class="MuiTouchRipple-root css-w0pj6f"></span></button>-->
            <button
                class="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root  css-654dsj"
                tabindex="0" type="button" @click="logout">로그아웃<span class="MuiTouchRipple-root css-w0pj6f"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <template
        v-if="!$route.path.includes('partner/')"
    >
      <div class="secondary_header close css-6iffui">
        <div class="MuiContainer-root MuiContainer-maxWidthLg css-10p9vcd">
          <div class="MuiGrid-root MuiGrid-container css-z68i50">
            <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 main_menu_wrap css-15j76c0">
              <div class="logo css-s2lwup"><a><img src="@/assets/img/logo.png" alt="LOGO" @click="$move('/')"></a></div>
              <ul class="main_menu">
                <li><a class="nav-link" @click="$move('/liveCasino')">라이브 카지노</a></li>
                <li><a class="nav-link" @click="launchGame({prdId:0})">슬롯</a></li>
                <li><a class="nav-link" @click="launchGame({prdId:10002})">히든포커</a></li>
                <li><a class="nav-link" @click="launchGame({prdId:108})">라이브 스포츠</a></li>

              </ul>
              <ul class="main_menu">
                <li><a class="nav-link" @click="launchGame({prdId:101})">E-스포츠</a></li>
                <li><a class="nav-link " @click="$move('/customer')">고객문의</a></li>
                <li><a class="nav-link " @click="$move('/notice/EVENT')">이벤트</a></li>
                <li><a class="nav-link " @click="$move('/notice/NOTICE')">이용규정</a></li>
                <li><a class="nav-link " @click="$move('/notice/FAQ')">FAQ</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template
        v-else
    >
      <div class="secondary_header css-6iffui"
           :class="openMenuListFlag? 'open': 'close'"
      >
        <div class="dimmed_layer"
             :class="openMenuListFlag? 'open': 'close'"
             @mouseover="openMenuList" @mouseleave="closeMenuList"
        ></div>
        <div class="MuiContainer-root MuiContainer-maxWidthLg css-10p9vcd">
          <div class="MuiGrid-root MuiGrid-container css-z68i50">
            <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 main_menu_wrap css-15j76c0">
              <div class="logo css-s2lwup" @click="$move('/')"><a><img src="@/assets/img/logo.png" alt="LOGO"></a></div>
              <ul class="main_menu" @mouseover="openMenuList" @mouseleave="closeMenuList">
                <li><a class="nav-link">홈</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/dashboard')">대시보드</li>
                  </ul>
                </li>
                <li><a class="nav-link">회원관리</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/member')">회원리스트</li>
                    <li class="sub_menu_link" @click="$move('/partner/logOn')">현재접속자</li>
                  </ul>
                </li>

              </ul>

              <ul class="main_menu" @mouseover="openMenuList" @mouseleave="closeMenuList">
                <li><a class="nav-link">충/환전</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/exchange')">충/환전 내역</li>
                  </ul>
                </li>
                <li><a class="nav-link">베팅</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/betHistory')">베팅 내역</li>
                  </ul>
                </li>
                <li><a class="nav-link">통계</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/gameSummary')">게임 통계</li>
                  </ul>
                </li>
                <!--
                <li><a class="nav-link">정산</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link">정산 내역</li>
                    <li class="sub_menu_link">정산 요청</li>
                  </ul>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: "userPc",
  data: () => ({
    openMenuListFlag: false

  }),
  props: {
    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    role: {
      type: String
    },
    hasChildren: {
      type: String
    }

  },
  methods: {
    logout: function () {
      this.$emit('logout');
    },
    goLink: function (target) {
      this.$emit('goLink', target);
    },
    openRecommenderDialog: function () {
      this.$emit('openRecommenderDialog')
    },
    openLoginDialog: function () {
      this.$emit('openLoginDialog')
    },
    openMenuList: function () {
      this.openMenuListFlag = true
    },
    closeMenuList: function () {
      this.openMenuListFlag = false
    },

    launchGame: function (item) {
      this.$emit('launchGame', item)
    },

  },
  computed: {
    // isLogin() {
    //   console.log('islogin',localStorage)
    //   return !!localStorage.accessToken;
    // }
  },
  watch: {}
}
</script>

<style scoped>
</style>
